import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageProps } from "@src/types";
import { t } from "@src/utils/translations";

import VideosTemplate from "./VideosTemplate";

export type VideosEvent = {
  body: string;
  photo: string;
};

export type VideosPageProps = PageProps;

class VideosPage extends React.Component<VideosPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Videos")}
          description={t(
            "Trucknet participates in different events such as the Cop21 convention in Paris 2015, the Technology week 'Viva Tech' etc.",
          )}
          keywords={[t("videos")]}
        />
        <VideosTemplate />
      </IndexLayout>
    );
  }
}

export default VideosPage;
