import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import OurVideos from "./OurVideos";
import { styles } from "./VideosTemplate.styles";

export type VideosTemplateProps = WithStyles<typeof styles> & {};

class VideosTemplate extends React.Component<VideosTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container>
        <Typography variant="h2" className={classes.title}>
          <T message="Videos" />
        </Typography>
        <OurVideos className={classes.ourVideos} />
      </Container>
    );
  }
}

export default withStyles(styles)(VideosTemplate);
